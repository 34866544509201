.form-container{
    max-width: rem-calc(540px);
    background: $white;
    border-radius: rem-calc(10px);
    padding: rem-calc(25px);
    position: relative;
    @include media-breakpoint-up(md){
        padding: rem-calc(50px 65px);
    }
    @include media-breakpoint-up(xl){
        padding: rem-calc(50px 75px);
    }
    &__title{
        margin-bottom: rem-calc(25px);
        h2{
            margin-bottom: rem-calc(20px);
        }
    }
    &--full-width{
        max-width: 100%;
        padding: 0;
        .form-container{
            &__title{
                 margin-bottom: rem-calc(40px);
                @include media-breakpoint-up(xxl){
                    margin-bottom: rem-calc(50px);
                }
                h2{
                    margin-bottom: rem-calc(15px);
                }
                p{
                    width: 50%;
                }
            }
        }   
    }
}
.c-form{
    &__wrapper{
        margin-right: auto;
        margin-left: auto;
        margin-top: rem-calc(30px);
        max-width: 320px;
        @include media-breakpoint-up(md) {
            margin-top: rem-calc(40px);
            max-width: 660px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(50px);
        }
    }
    &--secondary{
        .c-form--form-group{
            // background-color: red;
            margin-bottom:rem-calc(15px);
            @include media-breakpoint-up(md){
                margin-bottom: rem-calc(20px);
            }
            @include media-breakpoint-up(xl){
                margin-bottom: rem-calc(25px);
            }
        }
    }
    &--form-control{
        // border-radius: rem-calc(8px);
        // height: rem-calc(40px);
        // border:1px $gray-300 solid;
        // background-color: $white;
        // background-clip: border-box;
        // color: $black;
        // transition: $transition-base;
        // padding: rem-calc(10px);
        // @include media-breakpoint-up(xxl) {
        //     height: rem-calc(44px);
        // }
        // &::placeholder{
        //     color: $gray-700;
        // }
        // &:focus,
        // &:hover,
        // &:active{
        //     cursor: pointer;
        //     outline: none;
        //     color: $gray-700;
        //     border-color: $primary-color;
        //     outline: none;
        //     box-shadow:none;
        //     background-color: darken($white, .5%);
        // }
        &.error{
            border-color: $red;
            z-index: 2;
            border-width: 2px;
        }
         &.select{
            border-radius: 0px;
            appearance: none;
             background-color: white;
            position: relative;
            outline: none;
            opacity:0;
            visibility:hidden;
            height:0px;
            width:0px;
            &:focus,
            &:hover,
            &:active{
               z-index: 2;
               outline: none;
               box-shadow:none;
            }
        }
    }
    label {
        margin-bottom: rem-calc(8px);
        font-size: rem-calc(17px);
           @include media-breakpoint-up(xxl){

               font-size: rem-calc(20px);
           }
    }
    .buttons{
          margin-top: rem-calc(35px);
        @include media-breakpoint-up(xl){
            margin-top: rem-calc(45px);
        }
    }
    &--form-group{
        margin-bottom:rem-calc(20px);
        @include media-breakpoint-up(xl){
            margin-botto: rem-calc(30px);
        }
    }
    &--form-control-outline{
        color: $gray-700;
        border-bottom: 1px solid $gray-300;
        background: transparent;
        padding: 0;
        height: calc(1.5em + 0.5rem + 2px);
        &::placeholder{
            color: $gray-300;
            text-transform: capitalize;
        }
        &:focus{
            color: $gray-700;
            box-shadow: none;
            border-color: transparent   ;
            border-bottom: 1px solid $gray-700;
            background: transparent;
        }
        &:hover{
            cursor: pointer;
        }
       
    }
      &--login{
        margin-top: rem-calc(30px);
        max-width: 367px;
        width:100%;
        @include media-breakpoint-up(xl){
            margin-top: rem-calc(40px);
        }
        .form-group{
            &:not(:last-child){
                margin-bottom:rem-calc(20px);
                @include media-breakpoint-up(lg){
                    margin-bottom: rem-calc(25px);
                }
                @include media-breakpoint-up(xl){
                    margin-bottom: rem-calc(40px);
                }
            }
            
        }
        .buttons{
            margin-top:rem-calc(45px);
            @include media-breakpoint-up(xxl){
                margin-top: rem-calc(55px);
            }
        }
    }
    &__select{
        // border: 1px solid $gray-400;
        // background-color: $white;
        // color: $gray-700;
        position: relative;
        &::after{
            content:"";
            position:absolute;
            display: block;
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $gray-300;
            right:rem(10px);
            top:50%;
            transform:translateY(-50%);
        }
        // border-radius: 0.5rem;
        // &::after{
        //     content: "";
        //     width: 15px;
        //     height: 9px;
        //     background-image: url(.././images/icon-downward.svg);
        //     background-size: contain;
        //     background-repeat: no-repeat;
        //     position:absolute;
        //     top:50%;
        //     transform: translateY(-50%);
        //     right: rem-calc(10px);
        //     z-index: 3;
        //     @include media-breakpoint-up(xxl){
        //         width: 17px;
        //          height: 11px;
        //     }
        // }
         img{
            position: absolute;
            left: 30px;
            top:50%;
            transform: translateY(-50%);
            max-width: 22px;
            max-height: 24px;
            @include media-breakpoint-up(xxl){
                max-width: initial;
                max-height: initial;
            }
        }

        .form-control{
            height: rem-calc(40px);
            -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
            @include media-breakpoint-up(xxl){
                height: rem-calc(44px);
            }
            &.select{
                width:100%;
                height:100%;
                position: absolute;
            }
        } 
    }
}

// select

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}
.select {
    width:100%;
    cursor: pointer;
    display: block;
    position: relative;
}
.select-styled {
    position: relative; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: rem(11px 15px);
    display: flex;
    align-items: center;
    @include transition(all 0.2s ease-in);
    border: 1px solid $gray-400;
    background-color: $white;
    position: relative;
    border-radius: 0.5rem;
    color: $gray-800;
    &::after {
        position: absolute;
        content:"";
        width: 0px;
        height: 0px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $gray-400;
        top: 50%;
        right: rem(15px);
        transform: translateY(-50%);
    }
    &.active{
        border-radius: 0.5rem 0.5rem 0rem 0rem;
    }
}

.select-options {
    display: none; 
    position: absolute;
    top: calc(100% - 1px); 
    right: 0;
    left: 0;
    z-index: 11;
    margin: 0;
    padding: rem-calc(5px 0);
    list-style: none;
    background-color: $white;
    border: 1px solid $gray-400;
    overflow-y: scroll;
    max-height: rem-calc(210px);
    border-radius: 0 0 0.5rem 0.5rem;
    overflow: hidden;
    overflow-y: scroll;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, .1);
    // margin-left: -1.75px;
    // margin-right: -1px;
    li {
        margin: 0;
        padding: rem-calc(6px 20px);
        text-align: left;
        color:$gray-700;
        &:hover,
        &:focus,
        &:active{
            background-color: $gray-400;
            font-weight: 700;
            color:$gray-900;
        }
        &[rel="hide"] {
        display: none;
        }
    }
}

