
.list-card{
    max-width: 100%;
    @include media-breakpoint-up(md){
        max-width: rem-calc(400px);
    }
    @include media-breakpoint-up(lg){
        max-width: rem-calc(470px);
    }

    @include media-breakpoint-up(xl){
        max-width: rem-calc(500px);
    }
    &__item{
        &:not(:last-child){
            margin-bottom: rem-calc(10px);
        }
    }
    &__link{
        padding: rem-calc(15px 20px);
        border: 1px solid $white;
        border-radius: rem-calc(10px);
        cursor: pointer;
        display: block;
        color: $white;
        &::after{
            display: none;
        }
        @include media-breakpoint-up(xxl){
            padding: rem-calc(15px 25px);
        }
        h3{
            font-weight: 800;
            margin-bottom: rem-calc(10px);
        }
        &:hover{
            background-color: $white;
            border-color: $white;
            h3,p{
                color: $secondary-color;
            }
            @include media-breakpoint-up(lg){

            }
        }
    }
}