.ico-img{
    &.icon-fb,
    &.icon-phone{
        width: rem-calc(23px);
        height: rem-calc(23px);
        @include media-breakpoint-up(xxl){
            width: rem-calc(28px);
            height: rem-calc(28px);
        }
    }
    &.icon-mail{
        max-width: rem-calc(26px);
        height: rem-calc(18px);
        @include media-breakpoint-up(xxl){
            max-width: rem-calc(31px);
            height: rem-calc(22px);
        }
    }
    &.icon-location{
        max-width: rem-calc(18px);
        height: rem-calc(25px);
        @include media-breakpoint-up(xxl){
            max-width: rem-calc(23px);
            height: rem-calc(30px);
        }
    }
}