.c-banner{ 
    position: relative;
     background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color:$blue;
    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &--home{
        height: 400px;
        background-position: right;
        @include media-breakpoint-down(sm){
            background-image: none !important;
        }
        @include media-breakpoint-up(lg){
            height: 450px;
        }
        @include media-breakpoint-up(xl){
            height: rem-calc(590px);
        }
        @include media-breakpoint-up(xxl){
            height: rem-calc(660px);
        }
        &::before{
            background-color: $black;
            opacity: 0.3;
        }
    }
     &--secondary{
        height: 350px;
        @include media-breakpoint-down(sm){
            background-image: none !important;
        }
        @include media-breakpoint-up(xl){
            height: rem-calc(400px);
        }
        @include media-breakpoint-up(xxl){
            height: rem-calc(500px);
        }
    }
    &__content{
        position: relative;
        z-index: 1;
        max-width: rem-calc(500px);
        color:white;
        @include media-breakpoint-up(xxl){
            max-width: rem-calc(650px);
        }
        h1,p{
            color: $white;
        }
        h1{
            text-shadow: 0 0 20px rgba($black, .3);
            margin-bottom: rem-calc(15px);
        }
        div.h3{
            max-width: rem-calc(500px);
            text-shadow: 2px 2px 1px rgba($black, .2);
        }
        .buttons{
            margin-top: rem-calc(30px);
            @include media-breakpoint-up(md){
                margin-top: rem-calc(40px);
            }
            @include media-breakpoint-up(lg){
                margin-top: rem-calc(50px);
            }
            @include media-breakpoint-up(xl){
                margin-top: rem-calc(60px);
            }
        }
    }
}