/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `inuit-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$inuit-font-size-h1-xs:  22px !default;
$inuit-font-size-h2-xs:  20px !default;
$inuit-font-size-h3-xs:  16px !default;
$inuit-font-size-h4-xs:  14px !default;

$inuit-font-size-h1-md:  40px !default;
$inuit-font-size-h2-md:  32px !default;
$inuit-font-size-h3-md:  22px !default;
$inuit-font-size-h4-md:  16px !default;

$inuit-font-size-h1:  56px !default;
$inuit-font-size-h2:  46px !default;
$inuit-font-size-h3:  28px !default;
$inuit-font-size-h4:  18px !default;
$inuit-font-size-h5:  16px !default;
$inuit-font-size-h6:  14px !default;

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6{
//   font-family: $font-family-heading;
//   font-weight: 700;
//   letter-spacing: 0px;
// }

// h1 {
//   @include inuit-font-size($inuit-font-size-h1-xs);
//   @include mq($from: tablet) {
//     @include inuit-font-size($inuit-font-size-h1-md);
//   }
//   @include mq($from: desktop) {
//     @include inuit-font-size($inuit-font-size-h1);
//   }
// }

// h2 {
//   @include inuit-font-size($inuit-font-size-h2-xs);
//   @include mq($from: tablet) {
//     @include inuit-font-size($inuit-font-size-h2-md);
//   }
//   @include mq($from: desktop) {
//     @include inuit-font-size($inuit-font-size-h2);
//   }
// }

// h3 {
//   @include inuit-font-size($inuit-font-size-h3-xs);
//   @include mq($from: tablet) {
//     @include inuit-font-size($inuit-font-size-h3-md);
//   }
//   @include mq($from: desktop) {
//     @include inuit-font-size($inuit-font-size-h3);
//   }
// }

// h4 {
//   @include inuit-font-size($inuit-font-size-h4-xs);
//   @include mq($from: tablet) {
//     @include inuit-font-size($inuit-font-size-h4-md);
//   }
//   @include mq($from: desktop) {
//     @include inuit-font-size($inuit-font-size-h4);
//   }
// }

// h5 {
//   @include inuit-font-size($inuit-font-size-h5);
// }

// h6 {
//   @include inuit-font-size($inuit-font-size-h6);
// }



 //font-sizes used in site
//  breakpoints   1400   992   768   sm
//body            16     14    14    14
//25              25     20    17    17
//42              42     32    25    25     
//68              68     60    50    42

/*  mobile-up:  576px,
  tablet: 768px,
  tablet-up: 992px, 
  wide : 1400px */

$h1-font-sizes: (
  null: 36px,
  // mobile-up : 22px,
  tablet : 46px,
  tablet-up: 56px,
  xl-wide: 68px); 

$h2-font-sizes: (
  null: 28px,
  // mobile-up : 20px,
  tablet : 28px,
  tablet-up: 32px,
  xl-wide: 42px);

$h3-font-sizes: (
  null: 19px,
  //mobile-up : 16px,
  tablet : 19px, //768
  tablet-up: 20px, //992
  xl-wide: 25px); //1400

$h4-font-sizes: (
  null: 22px,
  //mobile-up : 14px,
  tablet : 24px,
  tablet-up: 26px,
  xl-wide: 28px);

  $h5-font-sizes: (
  null: 17.5px,
  tablet : 18px,
  tablet-up: 18px,
  xl-wide: 25px);

  $h6-font-sizes: (
    null: 18px,
    //mobile-up : 14px,
    tablet : 20px,
    tablet-up: 23px,
    xl-wide: 25px);

h1,
.h1{
    @include font-sizes($h1-font-sizes);
    // line-height: rem-calc(50px);
    // font-weight: 800;
    // @include media-breakpoint-up(md){
    //   line-height: rem-calc(60px);
    // }
    //   @include media-breakpoint-up(lg){
    //   line-height: rem-calc(70px);
    // }
    // @include media-breakpoint-up(xxxl){
    //   line-height: rem-calc(78px);
    // }
}

h2,
.h2{
  @include font-sizes($h2-font-sizes);
  // line-height: rem-calc(20px);
  // font-weight: 800;
  // @include media-breakpoint-up(md){
  //   line-height: rem-clac(30px);
  // }
  // @include media-breakpoint-up(lg){
  //   line-height: rem-calc(35px);
  // }
  // @include media-breakpoint-up(xxxl){
  //   line-height: rem-calc(45px);
  // }
}

h3,
.h3{
  @include font-sizes($h3-font-sizes);
  // line-height: rem-calc(16px);
  font-family: $font-family-base !important;
  // font-weight: 500;
  // @include media-breakpoint-up(lg){
  //   line-height: rem-calc(20px);
  // }
  // @include media-breakpoint-up(xxl){
  //   line-height: rem-calc(25px);
  // }
}

h4,
.h4{
  @include font-sizes($h4-font-sizes);
}

h5,
.h5{
  @include font-sizes($h5-font-sizes);
}

h6,
.h6{
  @include font-sizes($h6-font-sizes);
}