
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../fonts/Gilroy-ExtraBold.eot');
  src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gilroy-ExtraBold.otf') format('otf'),
       url('../fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg'),
       url('../fonts/Gilroy-ExtraBold.ttf') format('truetype'),
       url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
       url('../fonts/Gilroy-ExtraBold.woff2') format('woff2');
       font-display: swap;
       font-weight: normal;
font-style: normal;
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url('../fonts/Gilroy-Light.eot');
  src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gilroy-Light.otf') format('otf'),
       url('../fonts/Gilroy-Light.svg#Gilroy-Light') format('svg'),
       url('../fonts/Gilroy-Light.ttf') format('truetype'),
       url('../fonts/Gilroy-Light.woff') format('woff'),
       url('../fonts/Gilroy-Light.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../fonts/Gilroy-Medium.eot');
  src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gilroy-Medium.otf') format('otf'),
       url('../fonts//Gilroy-Medium.svg#Gilroy-Medium') format('svg'),
       url('../fonts//Gilroy-Medium.ttf') format('truetype'),
       url('../fonts//Gilroy-Medium.woff') format('woff'),
       url('../fonts//Gilroy-Medium.woff2') format('woff2');
       font-display: swap;
       font-weight: normal;
font-style: normal;
}

html{
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

body{
  font-size: 16px;
}

// body{
//   // font-size: rem-calc(15px);
//   // line-height: 1.2;
//   // font-weight: 400;
//   // font-family:  $font-family-base;
//   // color: $black;
//   // @include media-breakpoint-up(xxl){
//   //   font-size: rem-calc(16px);
//   // }
// }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
   font-family: $font-family-heading;

}

input[type=text],
textarea.form-control{
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

p{
  margin-bottom: 0;
}
ul{
   list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
a{
  transition: all .3s ease;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
figure{
  margin-bottom: 0;
}

.row{
  &--center{ 
    justify-content:center;
  }
}
img,svg{
  transition: all .3s ease;
}

.row--25 {
  @include media-breakpoint-up(md) {
    margin-left: -15px;
    margin-right: -15px;
  }
  @include media-breakpoint-up(md) {
    margin-left: -25px;
    margin-right: -25px;
  }
}
.col--25 {
   @include media-breakpoint-up(md) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-up(md) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.row--46 {
   @include media-breakpoint-up(md) {
    margin-left: -15px;
    margin-right: -15px;
  }
   @include media-breakpoint-up(xl) {
     margin-left: -18px;
    margin-right: -18px;
   }
  @include media-breakpoint-up(xxl) {
    margin-left: -18px;
    margin-right: -18px;
  }
  // @include media-breakpoint-up(xxxl) {
  //   margin-left: -46px;
  //   margin-right: -46px;
  // }
}
.col--46 {
  @include media-breakpoint-up(md) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-up(xl) {
      padding-left: 18px;
    padding-right: 18px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 18px;
    padding-right: 18px;
  }
  // @include media-breakpoint-up(xxxl) {
  //   padding-left: 46px;
  //   padding-right: 46px;
  // }
}
.row--50 {
   @include media-breakpoint-up(md) {
    margin-left: -25px;
    margin-right: -25px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: -50px;
    margin-right: -50px;
  }
}
.col--50 {
  @include media-breakpoint-up(md) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.row--58 {
   @include media-breakpoint-up(md) {
    margin-left: -30px;
    margin-right: -30px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: -58px;
    margin-right: -58px;
  }
}
.col--58 {
  @include media-breakpoint-up(md) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 58px;
    padding-right: 58px;
  }
}
.row--40 {
  @include media-breakpoint-up(md) {
    margin-left: -20px;
    margin-right: -20px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: -40px;
    margin-right: -40px;
  }
}
.col--40 {
   @include media-breakpoint-up(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.row--45 {
  @include media-breakpoint-up(md) {
    margin-left: -20px;
    margin-right: -20px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: -45px;
    margin-right: -45px;
  }
}
.col--45 {
   @include media-breakpoint-up(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 45px;
    padding-right: 45px;
  }
}
// .btn{
//   width: 100%;
//   padding: 12px 15px;
//   line-height: rem-calc(20px);
//   max-width: 190px;
//   font-size: rem-calc(16px);
//   @include media-breakpoint-up(lg){
//     font-size: rem-calc(18px);
//   }
//   @include media-breakpoint-up(xxl){
//     font-size: rem-calc(22px);
//      padding: 12px 14px;
//   }
//   @include media-breakpoint-up(xxxl){
//     font-size: rem-calc(25px);
//      padding: 14px 15px;
//   }
// }
// a.btn-auto, 
// .btn-auto{
//   max-width: fit-content;
//   width: auto;
// }
// a.btn-lg,
// .btn-lg{
//   max-width: 200px;
//   @include media-breakpoint-up(lg) {
//     max-width: 245px;
//   }
// }
// a.btn-primary,
// .btn-primary{
//   color: $white;
//   background: $primary-color;
//   border-color: $primary-color;
//   &:hover{
//     border-color: $white;
//     background: $white;
//     color: $primary-color;
//     box-shadow: 0 2px 5px darken($gray-300, 5%);
//   }
//   &:focus,
//   &:active{
//     border-color: $white !important;
//     background: darken($white, 3%) !important;
//     color: $primary-color !important;
//     box-shadow: 0 4px 6px lighten($gray-700, 20%) !important;
//   }
// }
// a.btn-light,
// .btn-light{
//   color: $white;
//   background: transparent;
//   border-color: $white;
//   &:hover{
//     border-color: $primary-color;
//     background-color: $primary-color;
//     color: $white;
//   }
// }
// a.btn-radius,
// .btn-radius{
//     border-radius: rem-calc(24px);
// }

section a, 
.common-link{
  color: $primary-color;
  position: relative;
  text-decoration: none;
  // font-size: rem-calc(18px);
  @include media-breakpoint-up(xxl){
    // font-size: rem-calc(21px);
  }
  // &::after{
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 1.5px;
  //   left: 0;
  //   bottom:3px;
  //   background: $primary-color;
  //   transition: all .2s linear;
  // }
  &:hover{
    text-decoration: none;
    color: $secondary-color;
    // &::after{
    //   width: 0;
    //   background: $secondary-color;
    // }
  }
}

@webkit-keyframes opacity{
  0%{
    opacity:0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes opacity{
  0%{
    opacity:0;
  }
  100%{
    opacity: 1;
  }
}

input{
  &:focus{
    outline: none;
  }
}

.btn-readmore{
  display: inline-block;
  margin-top: 0.625rem;
  font-size: 1.125rem;
  @include media-breakpoint-up(md){
    margin-top: 0.9375rem;
    font-size: 1.1875rem;
}
@include media-breakpoint-up(lg){
  margin-top: 1.5625rem;
  font-size: 1.3125rem;
}
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}

body{
  &.headerfixed{
    padding-top: 88px;
    @include media-breakpoint-up(lg){
      padding-top: 96px;
    }
    @include media-breakpoint-up(xl){
      padding-top: 115px;
    }
     @include media-breakpoint-up(xxl){
      padding-top: 117px;
    }
    @include media-breakpoint-up(xxxl){
      padding-top: 117px;
    }
    .c-header{
      position:fixed;
      width:100%;
      top:0;
      // + .c-banner{
      //   background-color: red;
      // }
    }
  }
}

