.c-header {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  z-index: 999;
  background-color: $white;
  transition: $transition-base;
  &--login {
    border-bottom: 2px solid $gray-300;
  }
  &__container {
    width: 100%;
    margin: auto;
    max-width: rem-calc(1200px);
    padding: rem-calc(0 20px);
    @include media-breakpoint-up(xl) {
      //  padding: 0;
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem-calc(1335px);
    }
    // padding: 0 50px;
  }
  &__menu-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      // background-color: red;
    }
  }
  &__inner {
    padding: rem-calc(15px 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $transition-base;
    @include media-breakpoint-up(xl) {
      padding: rem-calc(26px 0);
    }
    // @include media-breakpoint-up(xxxl){
    //     padding: rem-calc(26px 0);
    // }
  }
  &__toggle-button {
    position: relative;
    width: 42px;
    height: 44px;
    cursor: pointer;
    @include transition(all 0.5s ease-in-out);
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      // margin-left: rem(5px);
    }
    /*  @media only screen and (max-width: 991px){
           left: 80%;
       } */
    &:hover,
    &:active,
    &:focus {
      span {
        background-color: $primary-color;
      }
    }

    span {
      position: absolute;
      height: 2px;
      border-radius: 2px;
      width: calc(100% - 18px);
      background-color: $primary-color;
      top: 0;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      z-index: 1101;
      left: 10px;
      &:nth-child(1) {
        top: 14px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 22px;
      }

      &:nth-child(4) {
        top: 30px;
      }
    }

    &.active {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1000;
      left: auto;
      span {
        &:nth-child(1),
        &:nth-child(4) {
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          background-color: $black;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          background-color: $black;
        }
      }
    }
  }
  &__brand {
    img {
      max-width: rem-calc(140px);
      @include media-breakpoint-up(lg) {
        max-width: rem-calc(160px);
      }
      @include media-breakpoint-up(xl) {
        max-width: rem-calc(190px);
      }
      @include media-breakpoint-up(xxl) {
        max-width: rem-calc(220px);
      }
      @include media-breakpoint-up(xxxl) {
        max-width: rem-calc(235px);
      }
    }
  }
  &__menu-top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem-calc(0);
    // background-color: red;
    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(5px);
    }
    // @include media-breakpoint-up(xxl){
    //     margin-bottom: rem-calc(0);
    // }
  }
  &__menu {
    display: inline-flex;
    align-items: center;
  }

  &__menu-item {
    cursor: pointer;
    font-weight: 500;
    font-size: rem-calc(18px);
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up(xl) {
      margin-left: 0;
      margin-right: 0;
      // background-color: red;
    }
    // &:last-child,
    // &:nth-last-child(2) {
    //     padding-right: 0;
    // }
    &:last-child {
      // background-color: green;
      padding-bottom: rem(40px);
      padding-left: rem(20px);
      @include media-breakpoint-up(lg) {
        margin-right: 0;
        padding-bottom: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      padding-right: 0.3em;
      // background-color: green;
    }
    //     @include media-breakpoint-up(xl){
    //     padding-right: rem-calc(35px);
    // }
    // @include media-breakpoint-up(xxl){
    //     padding-right: rem-calc(40px);
    // }
    @include media-breakpoint-up(md) {
      font-size: rem-calc(20px);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem-calc(20px);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem-calc(21px);
    }

    //  @include media-breakpoint-up(xl){
    //     font-size: rem-calc(20px);
    // }
    &--wrap {
      display: flex;
      // @media only screen and (max-width: 1199px){
      //     padding-right: rem-calc(40px)
      // }
      @include media-breakpoint-down(xl) {
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;
      }
    }
    .separator {
      margin: rem-calc(0 5px);
      width: 1px;
      // height:100%;
      background-color: black;
      height: 30px;
      top: 50%;
      transform: translateY(8px);
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &__dropdown-content {
      // visibility: hidden;
      // opacity: 0;
      // transition: all 500ms ease;
      top: 110%;
      left: 0;
      // box-shadow: 0px 8px 16px 0px rgba($black, .2);
      padding: 0;
      position: absolute;
      // transform-origin: top;
      // animation-fill-mode: forwards;
      display: block;
      padding: rem(20px 0);
      border-top: 1px $gray-300 solid;
      box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.3);
      display: none;
      @include media-breakpoint-down(lg) {
        box-shadow: none;
        position: relative;
        top: auto;
        padding: rem(10px 15px 0 15px);
        text-align: left;
      }
    }
    &--dropdown {
      &.active {
        .c-header__menu-link {
          color: $teal;
        }
        .c-header__menu-item__dropdown-content {
          top: initial;
          opacity: 1;
          z-index: 1000;
          transition: transform 0.3s ease, opacity 0.2s ease;
          visibility: visible;
          display: block;
          cursor: default;
          @include media-breakpoint-up(xl) {
            top: 100%;
          }
        }
      }
    }
    &--megamenu {
      width: 100%;
      background-color: $white;
      // height: 50vh;
      @include media-breakpoint-up(xxxl) {
        // height: 55vh;
      }
      // overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: darken($gray-300, 10%);
      }
    }
    .wrapper {
      width: 100%;
      max-width: 100%;
      max-width: 472px;
      text-align: left;
      margin: auto;
      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
      @include media-breakpoint-up(xl) {
        max-width: 1058px;
        text-align: left;
      }
      @include media-breakpoint-up(xxl) {
        max-width: 1058px;
      }
    }
    .megamenu-content {
      margin: rem(15px auto);
      max-width: 235px;
      @include media-breakpoint-up(xxl) {
        margin: rem-calc(10px 0);
      }
      @include media-breakpoint-up(xxxl) {
        margin: rem-calc(15px 0);
      }

      h3 {
        font-family: $font-family-heading !important;
        margin-bottom: rem-calc(3px);
        font-size: rem-calc(16px);
        @include media-breakpoint-up(md) {
          font-size: rem-calc(16px);
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: rem-calc(3px);
          font-size: rem-calc(17px);
        }
        a {
          text-transform: initial !important;
          color: $body-color;
          &:hover {
            color: $primary-color;
          }
        }
      }
      li {
        line-height: 1;
        &:not(:last-child) {
          margin-bottom: rem-calc(3px);
          /* @include media-breakpoint-up(xxl){
                        margin-bottom: rem-calc(4px);
                    } */
          @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(3px);
          }
        }
        a {
          color: $black;
          font-size: rem-calc(14px);
          position: relative;
          transition: all 0.3s ease;
          /* @include media-breakpoint-up(xxl){
                        font-size: rem-calc(14px);
                    } */
          @include media-breakpoint-up(xl) {
            font-size: rem-calc(15px);
          }
          &:hover {
            color: $primary-color;
            padding-left: 4px;
          }
        }
      }
    }
  }
  button.c-header__menu-link {
    padding: 0;
    background-color: transparent;
    border: 0;
    text-align: left;
  }
  &__menu-link {
    color: $black;
    text-transform: capitalize;
    padding: rem(15px 20px);
    display: block;
    text-align: left;
    @include media-breakpoint-up(xl) {
      padding: rem(10px 15px);
    }
    svg {
      transition: all 0.4s ease;
    }
    &:hover {
      color: $primary-color;
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
    span {
      &.pl-10 {
        padding-left: rem-calc(10px);
      }
    }
    &.search-link {
      padding: rem(10px 12px);
      input.searchbox {
        width: 0;
        box-sizing: border-box;
        border: 0;
        border-radius: 0;
        border-bottom: 0 solid;
        line-height: 0;
        animation: opacity 0.4s ease;
        transition: width 0.4s ease-in-out;
        border-color: $gray-600;
        font-size: rem-calc(15px);
        display: none;
        line-height: 1;
        @include media-breakpoint-up(sm) {
          display: inline-block;
        }
        @include media-breakpoint-up(xl) {
          font-size: rem-calc(17px);
        }
        @include media-breakpoint-up(xxxl) {
          font-size: rem-calc(20px);
        }
        &::placeholder {
          color: $gray-600;
        }
        &:focus {
          outline: none;
          border-bottom: 1px solid;
          border-color: $primary-color;
          width: 200px;
          color: $gray-600;
          font-size: rem-calc(15px);
          @include media-breakpoint-up(xl) {
            font-size: rem-calc(17px);
          }
          @include media-breakpoint-up(xxxl) {
            font-size: rem-calc(20px);
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      &.active {
        color: $primary-color;
        input.searchbox {
          width: 200px;
          border-bottom: 1px solid;
        }
      }
    }
    &.user {
      padding: rem(10px 12px);
    }
  }
  &__search-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    background-color: $gray-300;
    transition: all 0.4s ease;
    max-height: 0;
    input {
      width: 100%;
      display: block;
      height: 100%;
      border: 0;
      background-color: transparent;
      color: $gray-700;
      cursor: pointer;
      opacity: 0;
      padding: 0 1.25rem;
      transition: all 0.4s ease;
      &:focus {
        background-color: rgba($white, 0.7);
        color: $black;
      }
    }
    &.show {
      max-height: rem-calc(50px);
      input {
        opacity: 1;
      }
    }
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  /*  .call-to-action{
        padding: rem-calc(4px);
    } */
  &__menu-bottom {
    display: flex;
    transition: $transition-base;
    margin-bottom: rem(-10px);
    @media only screen and (max-width: 1199px) {
      transform: none;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      height: 100%;
      max-width: 100%;
      padding: 15px 0;
      width: 100%;
      z-index: 999;
      top: 40px;
      left: -9999px;
      transition: opacity 0.25s ease-out, top 0.25s ease-out,
        visibility 0s linear 0.25s, left 0s linear 0.25s;
      margin-bottom: 0;
      // box-shadow: 0 0 8px 2px $gray-600;
      // background-image: linear-gradient(145deg ,$primary-color 0%, $primary-light 100%);
      background-color: white;
      .c-header {
        &__menu-link {
          color: $black;
          padding: rem(15px 20px);
          &:hover {
            color: $teal;
          }
        }
        &__menu {
          display: block;
          margin-top: rem(60px);
          width: 100%;
          // padding:40px 0;
          margin-left: 15px;
          margin-right: 15px;
        }
        // &__menu-item{
        //     &:not(:last-child) {
        //         padding-right: 0;
        //         padding-bottom: 0;
        //     }
        // }
      }
    }
  }
  &.headerShow {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    @include media-breakpoint-up(lg) {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
    }
    .c-header__inner {
      padding: rem-calc(14px) 0;
    }
    .c-header__brand img {
      @include media-breakpoint-up(lg) {
        max-width: rem-calc(160px);
      }
      @include media-breakpoint-up(xl) {
        max-width: rem-calc(165px);
        // background-color:blue;
      }
      @include media-breakpoint-up(xxl) {
        max-width: rem-calc(190px);
        // background-color: red;
      }
      @include media-breakpoint-up(xxxl) {
        max-width: rem-calc(200px);
        // background-color: green;
      }
    }
  }
}
.Is-toggle {
  .c-header {
    &__menu-bottom {
      visibility: visible;
      opacity: 1;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;
      left: 0;
      top: 0;
      transition: opacity 0.25s ease-out, top 0.25s ease-out, left 0s;
    }
  }
}

body {
  &.Is-toggle {
    overflow: hidden;
  }
}

.search-block {
  display: block;
  // background-color: red;
  position: relative;
  // margin-right: 10px;
  @include media-breakpoint-down(lg) {
    position: static;
  }
}

.search-toggle {
  display: block;
  &__btn {
    width: 47px;
    height: 47px;
    border: none;
    background-color: white;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icn-close {
      display: none;
    }
  }
  &.opened {
    .icn-close {
      display: flex;
    }
    .icn-search {
      display: none;
    }
  }
}

.search-container {
  position: relative;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: inherit;
  overflow: hidden;
  background-color: white;
  position: absolute;
  right: 100%;
  max-width: 0px;
  top: 0;
  border-bottom: 1px $gray-300 solid;
  @include media-breakpoint-down(lg) {
    max-height: 0px;
    right: 0;
    top: calc(100% - 1px);
    max-width: 100%;
    width: 100vw;
  }
  &.opened {
    max-width: 247px;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      width: 100vw;
      max-height: 47px;
    }
  }
  &__form {
    display: flex;
  }
  input[type="text"] {
    outline: none;
    font-size: 1rem;
    margin: 0;
    width: 200px;
    background-color: inherit;
    border: 0;
    height: 47px;
    padding: 5px 15px;
    @include media-breakpoint-down(lg) {
      width: calc(100% - 47px);
      background-color: $gray-100;
    }
  }
  .search-icon {
    vertical-align: middle;
  }
  &__search-btn {
    width: 47px;
    height: 47px;
    display: block;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: none;
    @include media-breakpoint-down(lg) {
      background-color: $gray-100;
    }
  }
}

.search-icon {
  // font-size: 32px;
  color: dimgray;
  background-color: white;
  border: 0;
  outline: none;
  border-bottom: 1px black solid;
}

.icn-search-svg {
  width: 20px;
  height: 22px;
  display: block;
}

.icn-search-close {
  width: 18px;
  height: 18px;
  display: block;
}

.mega-img {
  margin-bottom: rem-calc(10px);
  // display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
  @include media-breakpoint-up(xl) {
    // height: rem-calc(100px);
    margin-bottom: rem-calc(15px);
    // background-color: red;
  }
  @include media-breakpoint-up(xxl) {
    // margin-bottom: rem-calc(15px);
    // height: rem-calc(100px);
  }
  // @include media-breakpoint-up(xxxl){
  //     margin-bottom: rem-calc(25px);
  //      height: rem-calc(165px);
  // }
  a {
    position: relative;
    display: block;
    background-color: white;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    border-radius: rem(10px);
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      padding-bottom: 42.6%;
    }
  }
  img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    @include media-breakpoint-up(xxxl) {
      border-radius: 7px;
    }
    @include media-breakpoint-up(xxxl) {
      border-radius: 10px;
    }
  }
}

.c-brand-logo {
  max-width: rem-calc(140px);
  width: 100%;
  display: block;
  @include media-breakpoint-up(lg) {
    max-width: rem-calc(160px);
  }
  @include media-breakpoint-up(xl) {
    max-width: rem-calc(190px);
  }
  @include media-breakpoint-up(xxl) {
    max-width: rem-calc(220px);
  }
  @include media-breakpoint-up(xxxl) {
    max-width: rem-calc(235px);
  }
  &__blue {
    fill: $blue-one;
  }
  &__teal {
    fill: $teal-two;
    // transform: fill 100ms ease;
    // &:hover{
    //     fill: $teal-one;
    // }
  }
  &:hover {
    .c-brand-logo__teal {
      fill: $teal-one;
    }
  }
}
