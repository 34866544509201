.c-footer{
    padding: rem-calc(50px 0 40px);
    @include media-breakpoint-up(xl){
        padding: rem-calc(70px 0);
    }
    background-color: $secondary-color;
    &__top{
        border-bottom: 1px solid $white;
         padding-bottom: rem-calc(30px);
        @include media-breakpoint-up(xxl){
            padding-bottom: rem-calc(40px);
        }
    }
    &__bottom{
          padding-top: rem-calc(30px);
        @include media-breakpoint-up(xxl){
            padding-top: rem-calc(40px);
        }
    }
    &__brand-social-group{
         margin-bottom: rem-calc(45px);
        @include media-breakpoint-up(xxl){
            margin-bottom: rem-calc(55px);
        }
    }
    &__brand{
        text-align: center;
        @include media-breakpoint-up(md){
            text-align: left;
        }
        img{
            max-width: rem-calc(200px);
            @include media-breakpoint-up(lg){
                max-width: rem-calc(240px);
            }
            @include media-breakpoint-up(xxl){
                max-width: rem-calc(257px);
            }
        }
    }
    &__social-icons{
        text-align: center;
        display: flex;
        justify-content:center;
        @include media-breakpoint-up(md){
            text-align: left;
            justify-content:flex-end;
        }
        h2{
            color: $white;
            text-align: center;
            font-size: rem(20px);
            margin-bottom: .3em;
            @include media-breakpoint-up(md){
                font-size: rem(24px);
                text-align: right;  
            }
            @include media-breakpoint-up(lg){
                font-size: rem(31px);
                text-align: right;
                margin-bottom: .1em;
            }
        }
        &__lists{
            margin-top: rem-calc(20px);
            svg{
                width: 38px;
                height: 38px;
                @include media-breakpoint-up(lg){
                    width:48px;
                    height:48px;
                }
            }
        }
        &__lists-items{
            margin-right: rem-calc(35px);
        }
        &__list-links{
            &:hover{
                cursor: pointer;
                svg{
                    path{
                        fill: $primary-color;
                    }
                }
            }
        }
        // &-wrapper{
        //     display: flex;
        //     flex-direction:flex-end;
        // }
    }
    &__social-wrapper{
       display: block;
    //    background-color: red;
    }
    &__info{
        border-radius: rem-calc(10px);
        font-size: rem-calc(16px);
        color: $white;
        font-family: $font-family-heading;
        background: $primary-color;
        padding: rem-calc(20px 30px);
        margin-bottom: rem-calc(35px);
        text-align: center;
        @include media-breakpoint-up(lg){
            padding: rem-calc(20px 40px);
        }
        @include media-breakpoint-up(xxl){
            padding: rem-calc(25px 45px);
            font-size: rem-calc(18px);
            margin-bottom: rem-calc(48px);
        }
    }
    &__menu-col{
        text-align: center;
        @include media-breakpoint-up(md){
            text-align: left;
        }
        h3{
            color: $white;
            font-family: $font-family-heading !important;
            margin-bottom: rem-calc(15px);
            &.row-heading{
                margin-bottom: 25px;
                 @include media-breakpoint-up(xl){
                    margin-bottom: rem-calc(35px);
                }
                @include media-breakpoint-up(xxl){
                    margin-bottom: rem-calc(50px);
                }
            }
        }
    }
    &__menu-lists{
        li{
            margin-bottom: rem-calc(5px);
            a{
                color: $white;  
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    &__copyright{
        .copyright-col{
            text-align: center;
            @include media-breakpoint-up(md){
                text-align: left
            }
            span,a{
                color: $white;
            }
            a{
                &:hover{
                    color: $primary-color;
                    img{
                        opacity: .8;
                    }
                }
                &.andmine-logo{
                    // margin-top: rem-calc(7px);
                    display: inline-flex;
    align-items: center;
    margin-top: 0;
    line-height: 1;
                    img{
                        // max-width: 25px;
                        // padding-right: rem-calc(5px);
                        max-width: 18px;
    padding-right: 0.3125rem;
    /* height: 13px; */
    line-height: 1;
    /* background: yellow; */
    margin-left: 5px;
                    }
                }
            }
        }
    }
}