.c-searchpage{
    &__results{
        font-size: rem(20px);
        margin:rem(10px 0 40px 0);
        color:$gray-500;
    }
    &__list{
        display: flex;
        flex-direction: column;
        margin: rem(0 0 50px 0);
    }
    &__items{
        margin-bottom: rem(20px);
        display: flex;
        position: relative;
        padding-left: rem(15px);
        &::before{
            content:"\0000B7";
            display:inline-block;
            display: block;
            position: absolute;
            left: 0;
            width: 5px;
            font-size: 140%;
            color:$gray-500;
        }
    }
    &__block{
        display: block;
    }
    &__title{
        text-decoration: none;
        margin-bottom: rem(10px);
        color:$teal;
        font-size: rem(20px);
        &:hover{
            color:$blue;
        }
    }
    &__para{
        display: block;
        margin-top: rem(5px);
        color:$gray-550;
    }
}

.page-link--custom{
    width: rem(40px);
    height: rem(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon-full{
    width: 100%;
    height:100%;
    display:block;
}

.icon-arw{
    width: 18px;
    height: 18px;
    display: block;
}

// .icon-arw-left{
//     width: 22px;
//     height: 22px;
//     display: block;
// }

.page-item--custom{
    margin: 0 5px;
}
