$breakpoints: (
  mobile-up:  576px,
  tablet: 768px,
  tablet-up: 992px, 
  wide : 1400px,
  xl-wide: 1640px
);


@mixin font-sizes($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
      @if $fs-breakpoint == null {
        font-size: $fs-font-size;
      }
      @else {
        // If $fs-font-size is a key that exists in
        // $fs-breakpoints, use the value
        @if map-has-key($fs-breakpoints, $fs-breakpoint) {
          $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
        }
        @media screen and (min-width: $fs-breakpoint) {
          font-size: $fs-font-size;
        }
      }
    }
  }