.login{
    height: auto;
    //  height: calc(100vh - 119px);
    // @include media-breakpoint-up(lg){
    //     height: calc(100vh - 128px);
    // }
    //  @include media-breakpoint-up(xl){
    //     height: calc(100vh - 143px);
    // }
    // @include media-breakpoint-up(xxl){
    //     height: calc(100vh - 155px);
    // }
    &__content{
        padding: rem-calc(40px 25px);
        @include media-breakpoint-up(sm){
            justify-content:center;
        }
        @include media-breakpoint-up(md){
            justify-content:flex-end;
            align-items: center;
            padding: rem-calc(50px 25px);
        }
        @include media-breakpoint-up(xl){
            justify-content:flex-end;
            align-items: center;
            padding: rem-calc(80px 25px);
            min-height: 70vh;
        }
    }
    &__portal{
        margin:0;
        max-width: rem-calc(350px);
        // background-color: green;
        width: 100%;
        @include media-breakpoint-up(md){
            max-width: rem-calc(300px);
            padding-right: 2em
        }
        @include media-breakpoint-up(lg){
            // max-width: rem-calc(465px);
            max-width: 400px;
            padding-right: 6em;
        }
        @include media-breakpoint-up(xl){
            // max-width: rem-calc(465px);
            max-width: 550px;
            padding-right: 6em;
        }
        h3{
            font-family:  $font-family-heading !important;
        }
        &__links-list{
           margin-top: rem-calc(30px);
            @include media-breakpoint-up(md){
                margin-top: rem-calc(40px);
            }
            @include media-breakpoint-up(lg){
                margin-top: rem-calc(50px);
            }
            @include media-breakpoint-up(xl){
                margin-top: rem-calc(68px);
            }
            li{
                &:not(:last-child){
                    margin-bottom: rem-calc(20px);
                    @include media-breakpoint-up(xxl){
                        margin-bottom: rem-calc(30px);
                    }
                }
                a{
                    display: block;
                    width: 100%;
                    color: $primary-color;
                    background-color: $white;
                    border: 1px solid $primary-color;
                    border-radius: rem-calc(10px);
                    text-transform: capitalize;
                    padding: rem-calc(15px);
                    text-align: center;
                    @include media-breakpoint-up(xxl){
                         padding: rem-calc(20px);
                    }
                    &:hover{
                        color: $white;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
    &__form{
        margin:0;
        max-width: rem-calc(420px);
        // background-color: green;
        width: 100%;
        @include media-breakpoint-up(lg){
            // max-width: rem-calc(465px);
            max-width: 550px;
        }
        .h1{
            margin-bottom: rem-calc(24px);
        }
    }
    &__background-body{
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 300px;
        @include media-breakpoint-up(md){
            height: 100%;
        }
    }
}