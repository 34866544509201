.c-section {
  padding: rem-calc(50px 0);
  @include media-breakpoint-up(xl) {
    padding: rem-calc(70px 0);
  }
  &.buttons {
    //     display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    background-color: #6d6060;
    // > div{
    //     margin: 20px;
    // }
    color: white;
    h5 {
      margin-bottom: 30px;
    }
    a {
      margin: 20px 0;
    }
    .col-12 {
      flex-direction: column;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &__pos {
    position: relative;
    z-index: 1;
  }
  &--primary {
    background-color: $primary-color;
  }
  &--light {
    background-color: $gray-300;
  }
  &--bgimage {
    position: relative;
    &-listcard {
      background-size: auto;
      background-position: left bottom;
      // opacity: .25;
      background-repeat: no-repeat;
      @include media-breakpoint-up(md) {
        background-size: 44vw;
      }

      @include media-breakpoint-up(lg) {
        background-size: 50vw;
      }
      @include media-breakpoint-up(xl) {
        background-size: 50vw;
      }
      @include media-breakpoint-up(xxl) {
        background-size: 50vw;
      }
      @include media-breakpoint-up(xxxl) {
        background-size: 45vw;
      }
    }
    &-formcard {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      /*  &::before{
                background-image: url('../images/section-bg1.jpg');
            } */
    }
    &::after {
      @include media-breakpoint-down(sm) {
        content: " ";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($teal, 0.95);
      }
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }
  &__intro {
    margin-bottom: rem-calc(40px);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(50px);
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: rem-calc(63px);
    }
    &-vid {
      width: 100%;
      height: 300px;
      margin-bottom: rem-calc(20px);
      @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(30px);
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: rem-calc(40px);
      }
      figure,
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    &-para {
      font-size: rem(18px);
      @include media-breakpoint-up(md) {
        font-size: rem-calc(20px);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(22px);
      }
      p {
        &.p-image {
          img {
            width: 100%;
            height: auto;
          }
        }
        &:not(:last-child) {
          margin-bottom: rem-calc(20px);
          @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(30px);
          }
          @include media-breakpoint-up(xxl) {
            margin-bottom: rem-calc(40px);
          }
        }
      }
      ol {
        padding-left: rem(25px);
        li {
          &::marker {
            color: $teal;
          }
        }
      }
      ul {
        margin-bottom: rem-calc(20px);
        @include media-breakpoint-up(lg) {
          margin-bottom: rem-calc(30px);
        }
        @include media-breakpoint-up(xxl) {
          margin-bottom: rem-calc(40px);
        }
        li {
          padding-left: rem-calc(20px);
          position: relative;
          &:not(:last-child) {
            margin-bottom: 6px;
          }
          @include media-breakpoint-up(xxl) {
            padding-left: rem-calc(24px);
          }
          &:before {
            content: "circle";
            font-family: "Material Icons";
            position: absolute;
            color: $primary-color;
            font-size: rem-calc(6px);
            top: 8px;
            left: 0;
            transform: translate(0px, 0px);
            @include media-breakpoint-up(md) {
              top: 11px;
            }
            @include media-breakpoint-up(lg) {
              font-size: 8px;
              top: 9px;
            }
          }
        }
      }
    }
  }
}
.card-row {
  .row {
    .col {
      margin-bottom: rem-calc(30px);
      &:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(50px);
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: rem-calc(70px);
      }
    }
  }
}
.c-card {
  display: block;
  position: relative;
  height: 100%;
  &::after {
    display: none;
  }
  &__content {
    p {
      &.p-width {
        margin: auto;
        max-width: rem-calc(250px);
        @include media-breakpoint-up(md) {
          max-width: rem-calc(195px);
          margin: initial;
        }
      }
    }
    &-description {
      @include media-breakpoint-up(xl) {
        opacity: 1;
        visibility: visible;
        transition: opacity 100ms ease;
      }
    }
    .c-campuses__btn-blk {
      display: inline-block;
      margin-top: rem-calc(10px);
      font-size: rem(18px);
      @include media-breakpoint-up(md) {
        margin-top: rem-calc(15px);
        font-size: rem(19px);
      }
      @include media-breakpoint-up(xl) {
        margin-top: rem-calc(25px);
        font-size: rem(21px);
      }
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__imgholder {
    // background-color: red;
    img,
    .img-icon {
      width: 100%;
    }
  }
  &--icnholder {
    img,
    .img-icon {
      max-width: 100px;
      background-repeat: no-repeat;
      // background-position: 50% 50%;
      background-size: cover;
      overflow: hidden;
      height: auto;
      width: 100%;
      display: inline-block;
      &::before {
        content: "";
        padding-bottom: 100px;
        display: block;
      }
    }
  }
  &--hoverable {
    cursor: pointer;
    height: rem-calc(300px);
    @include media-breakpoint-up(xl) {
      height: rem-calc(360px);
    }
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.5;
      transition: all 0.3s ease;
    }
    .c-card__content {
      padding: rem-calc(15px);
      max-width: rem-calc(300px);
      width: 100%;
      z-index: 1;
      position: relative;
      @include media-breakpoint-up(xxl) {
        max-width: rem-calc(380px);
      }
    }
    .h2 {
      color: $white;
      padding-bottom: rem-calc(30px);
    }
    p {
      color: $white;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .buttons {
      position: relative;
      margin-top: rem(20px);
      @include media-breakpoint-up(xl) {
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 0;
        transition: all 200ms ease;
      }
    }
    &:hover {
      &::before {
        opacity: 1;
        background-color: transparent;
      }
      // .h2{
      //     padding-bottom: rem-calc(0px);
      // }
      .c-card__content-description {
        @include media-breakpoint-up(xl) {
          opacity: 0;
          visibility: hidden;
        }
      }
      .buttons {
        @include media-breakpoint-up(xl) {
          opacity: 1;
          visibility: visible;
          bottom: rem(25px);
        }
        // margin-top: rem-calc(68px);
      }
    }
  }
  &--plain {
    .c-card__content {
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
      figure {
        margin-bottom: rem-calc(20px);
        text-align: center;
        @include media-breakpoint-up(sm) {
          text-align: left;
        }
        @include media-breakpoint-up(xxl) {
          margin-bottom: rem-calc(25px);
        }
      }
      h3 {
        color: $black;
        font-weight: 800;
        margin-bottom: rem-calc(10px);
        @include media-breakpoint-up(xxl) {
          margin-bottom: rem-calc(15px);
        }
        a {
          color: $black;
        }
      }
      p {
        font-weight: 500;
      }
      .p-width {
        a {
          color: $black;
        }
      }
    }
  }
  &--clickable {
    cursor: pointer;
    .c-card__content {
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
      .c-story__btn-blk {
        display: inline-block;
        margin-top: rem-calc(10px);
        font-size: rem(18px);
        @include media-breakpoint-up(md) {
          margin-top: rem-calc(15px);
          font-size: rem(19px);
        }
        @include media-breakpoint-up(xl) {
          margin-top: rem-calc(25px);
          font-size: rem(21px);
        }
      }
      .c-story__link {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      h3 {
        margin-bottom: rem-calc(10px);
        @include media-breakpoint-up(xxl) {
          margin-bottom: rem-calc(15px);
        }
      }
      figure.c-card__imgholder {
        position: relative;
        margin: 0;
        margin: 0 auto rem-calc(20px);

        max-width: 220px;
        max-height: 220px;
        @include media-breakpoint-up(sm) {
          margin: 0 0 rem-calc(20px);
        }
        @include media-breakpoint-up(xxl) {
          margin-bottom: rem-calc(25px);
        }
        a {
          background-repeat: no-repeat;
          background-color: white;
          border-radius: rem(10px);
          background-size: cover;
          overflow: hidden;
          height: auto;
          max-width: 221px;
          display: block;
          &::before {
            content: "";
            display: block;
            padding-bottom: 221px;
          }
        }
        // &::after{
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        //     transition: $transition-base;
        //     background-color: rgba($black, 0);
        //     z-index: 11;
        // }
        img {
          &.img-pic {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }
      }
      h3 {
        font-family: $font-family-heading !important;
      }
    }
    // &:hover{
    //     .c-card__content{
    //         figure{
    //             &::after{
    //                 background-color: rgba($black, .2);
    //             }
    //         }
    //     }
    // }
  }
  &--box {
    border-radius: rem-calc(8px);
    background-color: $white;
    overflow: hidden;
    .c-card__content {
      padding: rem-calc(40px 55px);
      @include media-breakpoint-up(xxl) {
        padding: rem-calc(50px 65px);
      }
      @include media-breakpoint-up(xxl) {
        padding: rem-calc(60px 75px);
      }
    }
    figure {
      position: relative;
      // height: rem-calc(200px);

      @include media-breakpoint-up(lg) {
        // height: rem-calc(240px);
      }
      @include media-breakpoint-up(xxl) {
        // height: rem-calc(270px);
      }
      a {
        background-repeat: no-repeat;
        background-color: white;
        // background-position: 50% 50%;
        background-size: cover;
        overflow: hidden;
        max-width: 540px;
        height: auto;
        display: inline-block;
        width: 100%;
        &::before {
          content: "";
          padding-bottom: rem-calc(200px);
          display: block;
          @include media-breakpoint-up(lg) {
            // height: rem-calc(240px);
            padding-bottom: rem-calc(240px);
          }
          @include media-breakpoint-up(xxl) {
            // height: rem-calc(270px);
            padding-bottom: rem-calc(270px);
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // &::before{
      //     position: absolute;
      //     content: "";
      //     top: 0;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     width: 100%;
      //     height: 100%;
      //     transition: all .4s ease;
      // }
    }
    &__description {
    }
    &__btn {
      display: inline-block;
      margin-top: 0.625rem;
      font-size: 1.125rem;
      @include media-breakpoint-up(md) {
        margin-top: 0.9375rem;
        font-size: 1.1875rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: 1.5625rem;
        font-size: 1.3125rem;
      }
    }
    &__link {
      color: $teal;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    // &:hover{
    //     figure{
    //         &::before{
    //             background-color: rgba($black, .2);
    //         }
    //     }
    // }
  }
  &__newstitle {
    a {
      color: $black;
      &:hover {
        color: $teal;
      }
    }
  }
}

.info {
  max-width: 700px;
  @include media-breakpoint-up(lg) {
    max-width: rem-calc(850px);
  }
  @include media-breakpoint-up(xxl) {
    max-width: rem-calc(965px);
  }
}
