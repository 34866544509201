.common-title{
    color: $black;
    text-align: center;
    margin-bottom: rem-calc(52px);
    @include media-breakpoint-up(xxl){
        margin-bottom: rem-calc(62px);
    }
    p{
        padding-top: rem-calc(10px);
        max-width: rem-calc(620px);
        margin: auto;
        @include media-breakpoint-up(lg){
            max-width: rem-calc(720px);
        }
        @include media-breakpoint-up(xxl){
            max-width: rem-calc(965px);
            padding-top: rem-calc(15px);
        }
    }
    &--light{
        color: $white;
    }
}