.c-404 {
  &__title {
  }
  &__content {
    padding: rem(40px 0);
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
      padding: rem(70px 0);
      align-items: flex-start;
    }
    @include media-breakpoint-up(lg) {
      padding: rem(70px 0);
    }
  }
  &__block {
    padding-top: rem(20px);
    @include media-breakpoint-up(md) {
      padding-top: rem(30px);
    }
    @include media-breakpoint-up(lg) {
      padding-top: rem(40px);
      max-width: 80%;
    }
  }
  &__para {
    font-size: rem(14px);
    line-height: 1.3;
    @include media-breakpoint-up(md) {
      font-size: rem(18px);
      line-height: 1.4;
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(22px);
    }
  }
  &__imghodler {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  &__img {
    width: 100%;
    max-width: 100%;
    height: auto;
    // @include media-breakpoint-up(md) {
    //   max-width: 410px;
    // }
  }
}
